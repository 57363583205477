import { accentAwareCaseInsenstiveSortMany, arrayFindIndexOrFail, arrayFindIndexOrNull, exhaustiveCaseGuard, noAvailableOptions, requireNonNull, sortByDayJS, UiOptions, vReqT, WithDefiniteExpanded } from "src/helpers/utils";
import { DateTimelike, Guid, Integerlike, Season } from "src/interfaces/InleagueApiV1";
import { computed, defineComponent, onMounted, ref } from "vue";
import { MutUiSelection, UserLookup } from "./PracticeSchedulerActions";
import { FormKit } from "@formkit/vue";
import { Field } from "src/composables/InleagueApiV1";
import { createPracticeSlotAssignmentPermit, deletePracticeSlotAssignmentPermit, findSlotAssignmentUserCandidates, FindSlotAssignmentUserCandidatesResult, listPracticeSlotsAssignmentsPermits, PracticeSchedulerSeason, PracticeSlotAssignment, PracticeSlotAssignmentPermit } from "./PracticeScheduler.io";
import { ColDef, freshSortState } from "src/modules/TableUtils";
import dayjs from "dayjs";
import { teamDesignationAndMaybeName } from "./GameScheduler.shared";
import { Btn2, btn2_redEnabledClasses } from "src/components/UserInterface/Btn2";
import { ReactiveReifiedPromise, ReifiedPromise } from "src/helpers/ReifiedPromise";
import { WithDefinite2 } from "src/helpers/utils";
import { axiosAuthBackgroundInstance } from "src/boot/AxiosInstances";
import { XDump } from "src/helpers/XDump";
import { typedBasicAutoTable2Props, BasicAutoTable2 } from "src/modules/BasicAutoTable";
import { SoccerBall } from "src/components/SVGs";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { AxiosInstance } from "axios";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import iziToast from "izitoast";
import { AutoModal, DefaultModalController_r, DefaultTinySoccerballBusyOverlay, useDefaultNoCloseModalIfBusy } from "src/components/UserInterface/Modal";
import { FkDynamicValidation } from "src/helpers/FkUtils";

export const PracticeSchedulerPermitsMgrElement = defineComponent({
  props: {
    store: vReqT<PracticeSchedulerPermitsMgrStore>(),
    initialSeasonUID: vReqT<Guid>(),
  },
  setup(props) {
    const includeDeleted = ref(false)
    const colDefs : ColDef<PermitEx>[] = [
      {
        id: "user",
        headerClass: "border p-1",
        cellClass: "border p-1",
        label: "Name",
        html: v => `${v.user.firstName} ${v.user.lastName}`,
        sort: accentAwareCaseInsenstiveSortMany(v => v.user.lastName, v => v.user.firstName)
      },
      {
        id: "created",
        headerClass: "border p-1",
        cellClass: "border p-1",
        label: "Created",
        html: v => dayjs(v.creation.createdOn).format("MMM/DD/YYYY h:mm a"),
        sort: sortByDayJS(v => v.creation.createdOn)
      },
      {
        id: "comments",
        headerClass: "border p-1",
        cellClass: "border p-1",
        label: "Comments",
        html: v => v.comment
      },
      {
        id: "team",
        headerClass: "border p-1",
        cellClass: "border p-1",
        label: "Team",
        html: v => teamDesignationAndMaybeName(v.team)
      },
      {
        id: "consumed",
        headerClass: "border p-1",
        cellClass: "border p-1",
        label: "Used For",
        html: v => {
          if (!v.consumedBy) {
            return <div>unused</div>
          }
          const field = v.consumedBy.practiceSlot.field
          const start = dayjs(v.consumedBy.practiceSlot.start)
          const end = dayjs(v.consumedBy.practiceSlot.end)

          return <div>
            <div>{field.fieldAbbrev}</div>
            <div>{start.format("MMM/DD/YYYY")}, {start.format("h:mm a")} - {end.format("h:mm a")}</div>
          </div>
        }
      },
      {
        id: "delete",
        headerClass: "border p-1",
        cellClass: "border p-1",
        label: "",
        html: v => {
          if (v.deletion) {
            return <div>
              <div>Deleted {dayjs(v.deletion.deletedOn).format("MMM/DD/YYYY h:mm a")}</div>
              <div>by {v.deletion.deletedBy.firstName} {v.deletion.deletedBy.lastName}</div>
            </div>
          }
          else {
            return <Btn2
              class="px-2 py-1"
              onClick={() => doDeletePermit(v)}
              data-test="delete-button"
            >
              Delete
            </Btn2>
          }
        }
      }
    ]
    const sortState = freshSortState(colDefs)

    const doDeletePermit = async (permit: PermitEx) : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        await props.store.deletePermit(axiosAuthBackgroundInstance, permit)
      })
    }

    const createPermitModalController = (() => {
      const {busy, onCloseCB} = useDefaultNoCloseModalIfBusy()

      const submit = async (args: {
        seasonUID: Guid,
        userID: Guid,
        teamID: Guid,
        comment: string,
      }) => {
        try {
          try {
            busy.value = true
            await props.store.createPermit(axiosAuthBackgroundInstance, args)
          }
          finally {
            busy.value = false
          }
          createPermitModalController.close()
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }

      return DefaultModalController_r<{season: PracticeSchedulerSeason}>({
        title: () => <>
          <div>Create</div>
          <div class="my-2 border-b"/>
        </>,
        content: data => {
          if (!data) {
            return null
          }
          return <div>
            <CreatePermitModal
              season={data.season}
              onCommit={args => submit({
                seasonUID: data.season.seasonUID,
                teamID: args.teamID,
                userID: args.userID,
                comment: args.comment,
              })}
              onCancel={() => createPermitModalController.close()}
            />
            <DefaultTinySoccerballBusyOverlay if={busy.value}/>
          </div>
        }
      }, {onCloseCB})
    })()

    const rows = computed(() => {
      const vs = props.store.reportResolver.getOrNull()?.rows
      if (!vs) {
        return []
      }

      if (includeDeleted.value) {
        return vs
      }
      else {
        return vs.filter(v => v.deletion === null)
      }
    })

    onMounted(() => {
      if (props.store.seasonOptions.options.find(v => v.value === props.initialSeasonUID)) {
        props.store.seasonUID.selectedKey = props.initialSeasonUID
        props.store.loadReport({season: requireNonNull(props.store.seasonUID.selectedObj)})
      }
    })

    return () => {
      const store = props.store

      return <div>
        <AutoModal controller={createPermitModalController} data-test="createPermitModal"/>
        <div class="my-2 flex items-center gap-2">
          <FormKit
            type="select"
            disabled={store.seasonOptions.disabled}
            options={store.seasonOptions.options}
            data-test="seasonUID"
            placeholder="Select a season"
            v-model={store.seasonUID.selectedKey}
            onInput={(value: any) => {
              if (store.seasonUID.selectedKey === value) {
                return
              }
              store.seasonUID.selectedKey = value
              store.loadReport({season: requireNonNull(store.seasonUID.selectedObj)})
            }}
          />
          <Btn2
            class="px-2 py-1"
            data-test="create-button"
            disabled={!store.seasonUID.selectedObj || store.reportResolver.status !== "resolved"}
            onClick={() => store.seasonUID.selectedObj ? createPermitModalController.open({season: store.seasonUID.selectedObj}) : null}
          >
            Create
          </Btn2>
        </div>
        <div class="my-2 flex items-center">
          <FormKit
            type="checkbox"
            id="PermitsMgr-includeDeleted"
            v-model={includeDeleted.value}
            data-test="includeDeleted"
          />
          <label for="PermitsMgr-includeDeleted" class="text-sm">Show deleted items</label>
        </div>
        {store.reportResolver.status === "idle"
          ? null
          : <div class="my-2 overflow-x-auto">
            <BasicAutoTable2
              {...typedBasicAutoTable2Props({
              asXLSX: undefined,
              colDefs: colDefs,
              sortState: sortState,
              rows: rows.value,
              paginationOptions: [25, 50, "ALL"],
              rowKey: row => row.practiceSlotAssignmentPermitID.toString(),
              rowAttrs: row => ({"data-test": `practiceSlotAssignmentPermitID=${row.practiceSlotAssignmentPermitID}`}),
              noData: () => store.reportResolver.status === "idle"
                ? null
                : store.reportResolver.status === "pending"
                ? <div class="p-2 flex items-center gap-2"><SoccerBall/>Loading...</div>
                : store.reportResolver.status === "error"
                ? <div class="p-2">Sorry, something went wrong.</div>
                : store.reportResolver.status === "resolved"
                ? <div class="p-2">Nothing found for {store.reportResolver.data.season.seasonName}</div>
                : exhaustiveCaseGuard(store.reportResolver)
            })}/>
          </div>}
      </div>
    }
  }
})

const CreatePermitModal = defineComponent({
  props: {
    season: vReqT<PracticeSchedulerSeason>(),
  },
  emits: {
    commit: (_: {userID: Guid, teamID: Guid, comment: string}) => true,
    cancel: () => true,
  },
  setup(props, ctx) {
    const userOptions = ReactiveReifiedPromise<FindSlotAssignmentUserCandidatesResult[]>()

    const formState = (() => {
      const selectedUser = ref<null | FindSlotAssignmentUserCandidatesResult>(null)
      const selectedTeamID = ref("")
      const comment = ref("")
      return {
        selectedUser,
        selectedTeamID,
        comment,
        reset: () => {
          selectedUser.value = null
          selectedTeamID.value = ""
          comment.value = ""
        }
      }
    })()

    return () => {
      return <div>
        <div>
          <div>Find a user with a coach assignment in {props.season.seasonName}</div>
            <UserLookup
              class="my-2"
              season={props.season}
              searchResults={userOptions.underlying}
              selectedUser={formState.selectedUser.value}
              kludge_noCoachColumn={true}
              onLookupRequest={searchText => {
                formState.reset()
                userOptions.run(() => {
                  return findSlotAssignmentUserCandidates(axiosAuthBackgroundInstance, {
                    seasonUID: props.season.seasonUID,
                    searchText: searchText
                  })
                })
              }}
              onSelectedUser={userish => {
                formState.selectedUser.value = userish
              }}
            />
            {formState.selectedUser.value
              ? <div>
                <table>
                  <tr>
                    <td class="p-2 border"></td>
                    <td class="p-2 border">Team</td>
                  </tr>
                  {formState.selectedUser.value
                    ? formState.selectedUser.value.coachAssignments.map(ca => {
                      const keyAndID = `permitTeam-${ca.coachAssignmentID}`
                      return <tr key={keyAndID}>
                        <td class="border p-2">
                          <input type="radio" class="transition" id={keyAndID} data-test="team-radio" name="permitModalTeam" value={ca.teamID} v-model={formState.selectedTeamID.value}/>
                        </td>
                        <td class="border p-2">
                          <label for={keyAndID}>
                            {teamDesignationAndMaybeName(ca)}
                          </label>
                        </td>
                      </tr>
                    })
                    : null}
                </table>
                <p class="block my-2 text-sm">Comments:</p>
                <textarea class="block my-2 w-full rounded-md" data-test="comment" v-model={formState.comment.value}/>
              </div>
              : null}
            <div class="mt-4 flex items-center gap-2">
              <Btn2
                class="px-2 py-1"
                disabled={!formState.selectedUser.value || !formState.selectedTeamID.value}
                onClick={() => ctx.emit("commit", {teamID: formState.selectedTeamID.value, userID: requireNonNull(formState.selectedUser.value).userID, comment: formState.comment.value})}
                data-test="submit-button"
              >OK</Btn2>
              <Btn2 class="px-2 py-1" enabledClasses={btn2_redEnabledClasses} onClick={() => ctx.emit("cancel")}>Cancel</Btn2>
            </div>
        </div>
      </div>
    }
  }
})

const PermitEx = "consumedBy.practiceSlot.field"
type PermitEx = WithDefiniteExpanded<PracticeSlotAssignmentPermit, typeof PermitEx>

export function PracticeSchedulerPermitsMgrStore(args: {seasons: PracticeSchedulerSeason[]}) {
  const seasonOptions : UiOptions = args.seasons.length === 0
    ? noAvailableOptions()
    : {disabled: false, options: args.seasons.map(v => ({label: v.seasonName, value: v.seasonUID}))};

  const seasonUID = MutUiSelection<Guid, PracticeSchedulerSeason | null>(
    seasonUID => args.seasons.find(season => season.seasonUID === seasonUID) ?? null,
    ""
  )

  const reportResolver = ReactiveReifiedPromise<{season: Season, rows: PermitEx[]}>(undefined, {defaultDebounce_ms: 250})
  const loadReport = (args: {season: Season}) => {
    reportResolver.run(async () => {
      return {
        season: args.season,
        rows: (await listPracticeSlotsAssignmentsPermits(axiosAuthBackgroundInstance, {seasonUID: args.season.seasonUID, includeDeleted: true, expand: [PermitEx]})) as PermitEx[]
      }
    })
  }

  const createPermit = async (ax: AxiosInstance, args: {
    seasonUID: Guid,
    userID: Guid,
    teamID: Guid,
    comment: string,
  }) : Promise<void> => {
    try {
      const data = reportResolver.underlying.getOrNull()
      if (!data) {
        return
      }

      const permit = await createPracticeSlotAssignmentPermit(ax, {
        seasonUID: args.seasonUID,
        teamID: args.teamID,
        userID: args.userID,
        comment: args.comment,
        expand: [PermitEx]
      }) as PermitEx

      data.rows.unshift(permit)
    }
    catch (err) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(err)
    }
  }

  const deletePermit = async (ax: AxiosInstance, args: {practiceSlotAssignmentPermitID: number}) : Promise<void> => {
    try {
      const data = reportResolver.underlying.getOrNull()
      if (!data) {
        return
      }

      const updated = (await deletePracticeSlotAssignmentPermit(ax, {practiceSlotAssignmentPermitID: args.practiceSlotAssignmentPermitID, expand: [PermitEx]})) as PermitEx
      const idx = arrayFindIndexOrNull(data.rows, v => v.practiceSlotAssignmentPermitID === args.practiceSlotAssignmentPermitID)
      if (idx !== null) {
        data.rows[idx] = updated
      }
    }
    catch (err) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(err)
    }
  }

  return {
    seasonUID,
    seasonOptions,
    loadReport,
    createPermit,
    deletePermit,
    get reportResolver() { return reportResolver.underlying }
  }
}

export type PracticeSchedulerPermitsMgrStore = ReturnType<typeof PracticeSchedulerPermitsMgrStore>
