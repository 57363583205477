import { AxiosInstance } from "axios";
import { Field, isAxiosInleagueApiError } from "src/composables/InleagueApiV1";
import { rpc } from "src/composables/Rpc";
import { Datelike, DateTimelike, Division, Guid, Integerlike, Numbool, Season, TeamID } from "src/interfaces/InleagueApiV1";

export interface PracticeSlot {
  practiceSlotID: number,
  practiceSlotGroupID: number | "",
  fieldUID: Guid,
  field?: Field,
  seasonUID: Guid,
  divIDs: Guid[],
  start: DateTimelike,
  end: DateTimelike,
  visibleOnOrAfter: DateTimelike,
  hasSomeIntraFieldPracticeSlotConflict: Numbool,
  atCapacity: boolean,
  activeAssignments?: PracticeSlotAssignment[],
  currentSignedUpTeamIDs: TeamID[],
  allowableTeamCount: number,
  effectiveAllowableTeamCount: number,
}

export interface CreatePracticeSlotArgs {
  fieldUID: Guid,
  seasonUID: Guid,
  divIDs: Guid[],
  slots: CreatePracticeSlotArgs_SlotDef[],
}

export interface CreatePracticeSlotArgs_SlotDef {
  startDate: DateTimelike,
  startHr24: number,
  startMinute: number,
  endDate: DateTimelike,
  endHr24: number,
  endMinute: number,
  /**
   * string containing only a-z             --> generate a new recurrence group
   * integer (or string that is an integer) --> use existing recurrence group
   * undefined                              --> no recurrence group
   */
  recurrenceGroupID: string | number | undefined,
  visibleOnOrAfter: null | DateTimelike,
  allowableTeamCount: Integerlike,
}

export const getPracticeSlot = rpc<{practiceSlotID: number, expand?: PracticeSlotExpandable[]}, PracticeSlot>("get", "v1/practice/getPracticeSlot");

/**
 * should produce same result string as `timeChunkedSlotAssignmentCountKeyFormat` on backend
 */
export const kAssignmentsPerFieldPerTimeChunkDateTimeKeyDayjsFormat = "mm/dd/yy H:mm"

export async function listPracticeSlots(ax: AxiosInstance, args: {
  seasonUID?: Guid,
  dateRange?: {
    start: DateTimelike,
    end: DateTimelike,
  },
  practiceSlotGroupID?: Integerlike | "",
  fieldUIDs?: Guid[],
  divID?: Guid,
  includeDeleted?: boolean,
  expand?: PracticeSlotExpandable[]
}) : Promise<PracticeSlot[]> {
  // TODO: some serializer for nested objects over GET requests
  const d = args.dateRange
  delete args.dateRange
  if (d) {
    (args as any).dateRange_start = d.start;
    (args as any).dateRange_end = d.end;
  }
  return await ax.get("v1/practice/listPracticeSlots", {params: args}).then(res => res.data.data.practiceSlots)
}

/**
 * There is no guarantee on the ordering of the results with respect to the ordering of the requested slots
 */
export async function createPracticeSlots(ax: AxiosInstance, args: CreatePracticeSlotArgs) : Promise<PracticeSlot[]> {
  return await ax.post("v1/practice/createPracticeSlots", args).then(res => res.data.data.practiceSlots)
}

export interface GetCreatePracticeSlotsFormDataResponse {
  authZ: boolean,
  seasons: PracticeSchedulerSeason[],
  fields: Field[],
  divisions: Division[],
}
export const getCreatePracticeSlotsFormData = rpc<void, GetCreatePracticeSlotsFormDataResponse>("get", "v1/practice/getCreatePracticeSlotsFormData")

export interface PracticeSlotAssignmentData1 {
  seasons: PracticeSchedulerSeason[]
}

export const getPracticeSlotAssignmentData1 = rpc<{userID: Guid}, PracticeSlotAssignmentData1>("get", "v1/practice/getPracticeSlotAssignmentData1")

export interface PracticeSchedulerTeamInfo {
  seasonUID: Guid,
  team: {
    teamID: Guid,
    teamDesignation: string,
    teamName: string,
  },
  division: {
    divID: Guid,
    divNum: number,
    gender: string,
    displayName: string,
    division: string,
  }
}

export interface PracticeSlotAssignmentData2 {
  userID: Guid,
  seasonUID: Guid,
  teams: PracticeSchedulerTeamInfo[],
  alreadyHasSelectionForTheseTeams: Guid[],
  /**
   * "availablePermits" are those that are not deleted or consumed
   */
  availablePermits: {seasonUID: Guid, practiceSlotAssignmentPermitID: number, teamID: Guid}[],
}

export const getPracticeSlotAssignmentData2 = rpc<{userID: Guid, seasonUID: Guid}, PracticeSlotAssignmentData2>("get", "v1/practice/getPracticeSlotAssignmentData2")

export interface CreatePracticeSlotAssignmentArgs {
  userID: Guid,
  teamID: Guid,
  practiceSlotIDs: Integerlike[],
  practiceSlotAssignmentPermitID: undefined | number,
  expand?: PracticeSlotAssignmentExpandable[]
}

export async function createPracticeSlotAssignments(ax: AxiosInstance, args: CreatePracticeSlotAssignmentArgs) : Promise<{errorCode?: "unavailable", assignments?: PracticeSlotAssignment[]}> {
  try {
    return {assignments: await createPracticeSlotAssignments.rawRPC(ax, args)}
  }
  catch (err) {
    if (isAxiosInleagueApiError(err)) {
      if (err.response.data.data.errorCode === "unavailable") {
        return {errorCode: "unavailable"}
      }
    }
    throw err;
  }
}
createPracticeSlotAssignments.rawRPC = rpc<CreatePracticeSlotAssignmentArgs, PracticeSlotAssignment[]>("post", "v1/practice/createPracticeSlotAssignments")

export interface PracticeSlotAssignment {
  practiceSlotAssignmentID: number,
  practiceSlotAssignmentGroupID: number | null,
  practiceSlotID: number,
  practiceSlot?: PracticeSlot,
  seasonUID: Guid,
  type: 'coachAssignment',
  userID: Guid,
  deletion: null | {
    deletedOn: DateTimelike,
    deletedBy: {userID: Guid, firstName: string, lastName: string}
  },
  coachAssignment: {
    coachAssignmentID: Guid,
    seasonUID: Guid,
    team: {
      teamID: Guid,
      teamDesignation: string,
      teamName: string,
    },
    division: {
      divID: Guid,
      divNum: number,
      gender: string,
      displayName: string,
      division: string,
    },
    user: {
      firstName: string,
      lastName: string,
      email: string,
    },
  }
}

export type PracticeSlotExpandable = "activeAssignments" | "field"
export type PracticeSlotAssignmentExpandable = "practiceSlot" | "practiceSlot.field"

export const listPracticeSlotAssignments = rpc<{
  seasonUID: Guid,
  userID: Guid,
  practiceSlotAssignmentGroupID?: Integerlike,
  includeDeleted: boolean,
  expand?: PracticeSlotAssignmentExpandable[],
}, PracticeSlotAssignment[]>("get", "v1/practice/listPracticeSlotAssignments")

export const deletePracticeSlotAssignment = rpc<{practiceSlotAssignmentID: Integerlike, deleteAllGroupMembers: boolean, generateLooseSignupPermit: boolean, permitComment: string | undefined}, void>("post", "v1/practice/deletePracticeSlotAssignment")
export interface FindSlotAssignmentUserCandidatesResult {
  userID: Guid,
  firstName: string,
  lastName: string,
  email: string,
  coachAssignments: {
    coachAssignmentID : Guid,
    divID : string,
    divNum : Integerlike,
    gender : string,
    teamID: Guid,
    teamDesignation : string,
    teamName : string,
  }[]
}

export const findSlotAssignmentUserCandidates = rpc<
  {seasonUID: Guid, searchText: string},
  FindSlotAssignmentUserCandidatesResult[]
>("get", "v1/practice/findSlotAssignmentUserCandidates")

export interface PracticeSlotsAssignmentsReportRow {
  practiceSlotAssignmentID: number,
  start: DateTimelike,
  end: DateTimelike,
  user: {
    firstName: string,
    lastName: string,
    email: string,
  }
  team: {
    teamID: Guid,
    teamDesignation: string,
    teamName: string,
  },
  division: {
    divNum: Integerlike,
    gender: string,
    division: string,
    displayName: string,
  }
  field: Field,
}

export interface PracticeSlotAssignmentPermit {
  practiceSlotAssignmentPermitID: number,
  user: {
    userID: Guid,
    firstName: string,
    lastName: string,
  },
  creation: {
    createdOn: string,
    createdBy: {
      userID: Guid,
      firstName: string,
      lastName: string,
    },
  },
  deletion: null | {
    deletedBy: {
      userID: Guid,
      firstName: string,
      lastName: string,
    }
    deletedOn: DateTimelike,
  }
  comment: string,
  consumedBy: "" | number | PracticeSlotAssignment
  season: {
    seasonUID: Guid,
    seasonName: string,
    seasonID: number,
  },
  team: {
    teamID: Guid,
    seasonUID: Guid,
    teamDesignation: string,
    teamName: string,
    divID: Guid,
    divNum: number,
    gender: string,
  }
}

export type PracticeSlotAssignmentPermitExpandable =
  | "consumedBy"
  | "consumedBy.practiceSlot"
  | "consumedBy.practiceSlot.field"

export const createPracticeSlotAssignmentPermit = rpc<{
  seasonUID: Guid,
  teamID: Guid,
  userID: Guid,
  comment: string,
  expand?: PracticeSlotAssignmentPermitExpandable[]
}, PracticeSlotAssignmentPermit>("post", "v1/practice/createPracticeSlotAssignmentPermit")

export const deletePracticeSlotAssignmentPermit = rpc<
  {
    practiceSlotAssignmentPermitID: number,
    expand?: PracticeSlotAssignmentPermitExpandable[]
  }
  , PracticeSlotAssignmentPermit>("post", "v1/practice/deletePracticeSlotAssignmentPermit")

export const listPracticeSlotsAssignmentsPermits = rpc<
  {
    seasonUID: Guid,
    includeDeleted: boolean,
    expand?: PracticeSlotAssignmentPermitExpandable[]
  }, PracticeSlotAssignmentPermit[]>("get", "v1/practice/listPracticeSlotAssignmentsPermits")

export const deletePracticeSlots = rpc<{practiceSlotIDs: number[]}, {deletedPracticeSlotAssignments: {practiceSlotID: number}}[]>("post", "v1/practice/deletePracticeSlots")
export const undeletePracticeSlot = rpc<{practiceSlotID: number, practiceSlotAssignmentIDs: number[]}, void>("post", "v1/practice/undletePracticeSlots")
export const updatePracticeSlotDivisions = rpc<{practiceSlotID: number, practiceSlotGroupID: undefined | number, divIDs: Guid[]}, PracticeSlot>("post", "v1/practice/updatePracticeSlotDivisions")
export const unlinkPracticeSlot = rpc<{practiceSlotID: number, practiceSlotGroupID: number}, PracticeSlot>("post", "v1/practice/unlinkPracticeSlot")
export const linkPracticeSlot = rpc<{practiceSlotID: number, practiceSlotGroupID: number}, PracticeSlot>("post", "v1/practice/linkPracticeSlot")

export const updatePracticeSlot = rpc<{
  practiceSlotID: number,
  practiceSlotGroupID: number | null | "",
  allowableTeamCount?: Integerlike,
  visibleOnOrAfter?: DateTimelike | "",
  expand?: PracticeSlotExpandable[]
}, PracticeSlot>("post", "v1/practice/updatePracticeSlot");
export const getSeasonsForPracticeScheduler = rpc<void, PracticeSchedulerSeason[]>("get", "v1/practice/getSeasonsForPracticeScheduler")

/**
 * seasonStart is deprecated on season, so we need some additional info
 */
export type PracticeSchedulerSeason = Season & {coreProgramSeasonalInfo: null | {seasonStart: "" | Datelike}}
